import styled, { css } from 'styled-components';

const ExtendLink = css`
    font-size: .9em;
    cursor: pointer;
    font-weight: 400;
    display: block;
    padding: 5px 0;
    text-align: left;
    color: var(--light);

    &:focus {
        outline: 0;
    }
`;

export const Wrapper = styled.nav`
    position: absolute;
    left: 0;
    width: 200px;
    display: none;
    z-index: 2;
`;

export const Nav = styled.ul`
    position: relative;
    background-color: #fff;
    padding: 1em;
    box-shadow: 0 2px 18px 0 rgba(162,204,244,0.5);
    z-index: 3;

    > li {
        position: relative;
    }
`;

export const Category = styled.a`
    background: transparent;
    border: 0;
    ${ExtendLink}
    display: inline-block;

    &:hover, &.__clicked {
        font-weight: 700;
        color: var(--primary);
    }
`;

export const Sub = styled.ul`
    border-radius: 0 5px 5px 0;
    background-color: #FFF;
    box-shadow: 0 2px 18px 0 rgba(162,204,244,0.5);
    padding: 1em;
    width: 200px;
    z-index: 2;

    > li {
        display: inline-block;
        vertical-align: top;
        margin-right: 5px;
        width: 100%;

        &:first-of-type {
            width: 100%;

            > a {
                font-weight: 700;
                color: var(--normal);

                &:hover {
                    font-weight: 700;
                    color: var(--primary);
                }
            }
        }
    }
`;

export const SubCategory = styled.a`
    ${ExtendLink}

    &:hover {
        color: var(--primary);
    }
`;
