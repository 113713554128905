import Link from 'next/link';
import { useRouter } from 'next/router';
import { getCategoryTitle, generateUrl } from '@src/utils';
import * as Styles from './SubSegment.styled';

const SubSegment = ({ showed, segment, data }) => {
    const { locale } = useRouter();

    return (
        <Styles.Wrapper className={showed ? 'd-block' : ''}>
            <Styles.Nav>
                { data.map((subSegment) => (
                    <li key={subSegment.id}>
                        <Link href={generateUrl({ levelOne: segment, levelTwo: subSegment, locale })} passHref prefetch={false}>
                            <Styles.SubCategory className="productGroup_link">{getCategoryTitle(subSegment, locale)}</Styles.SubCategory>
                        </Link>
                    </li>
                ))}
            </Styles.Nav>
        </Styles.Wrapper>
    );
};

export default SubSegment;
